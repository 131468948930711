import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

export function exportOPsToXLSX(data) {
  const header = [
    'OP',
    'Data Criação',
    'Data Início',
    'Data Vencimento',
    'Projeto',
    'Item',
    'Descrição',
    'Status'
];
  const rows = [];

  data.forEach((item) => {
    rows.push([
      item.DocumentNumber,
      item.created.format('DD/MM/YYYY'),
      item.start.format('DD/MM/YYYY'),
      item.due.format('DD/MM/YYYY'),
      item.Project,
      item.ItemNo,
      item.ProductDescription,
      item.Status,
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'OrdensDeProducao');
  XLSX.writeFile(wb, `${dayjs().format('YYYY-MM-DD-HH-mm')}-OrdensDeProducao.xlsx`);
};
