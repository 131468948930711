import { CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import Cookies from "js-cookie";

const TransfersTable = ({ lines, icon, showMU, onClick, enabledCallback, filterCallback, highlightCallback, isLoading }) => {

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={showMU ? {} : { display: 'none' }}>Colaborador</TableCell>
          <TableCell>Item</TableCell>
          <TableCell sx={showMU ? { display: { xs: 'none', lg: 'table-cell' } } : { display: 'none' }}>Descrição</TableCell>
          <TableCell>Qtd.</TableCell>
          <TableCell sx={showMU ? {} : { display: 'none' }}>UM</TableCell>
          <TableCell sx={!showMU ? { display: { xs: 'none', lg: 'table-cell' } } : { display: 'none' }}>Status</TableCell>
          <TableCell align="center" />
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'auto' }}>
        <RenderlinesRows
          lines={lines}
          onClick={onClick}
          icon={icon}
          showMU={showMU}
          enabledCallback={enabledCallback}
          filterCallback={filterCallback}
          highlightCallback={highlightCallback}
          isLoading={isLoading}
        />
      </TableBody>
    </Table>
  );
};

function RenderlinesRows({ lines, onClick, icon, showMU, enabledCallback, filterCallback, highlightCallback, isLoading }) {
  const rows = [];
  if (isLoading) {
    rows.push(
      <TableRow><TableCell align="center" colSpan={5} variant="footer" ><CircularProgress /></TableCell></TableRow>
    );
    return rows;
  }
  for (let i = 0; i < lines.length; i++) {
    if (!filterCallback(lines[i])) {
      continue;
    }
    rows.push(
      <TableRowComponent
        disabled={enabledCallback ? !enabledCallback(lines[i]) : false}
        index={i}
        key={i}
        line={lines[i]}
        onClick={onClick}
        icon={icon}
        showMU={showMU}
        highlight={highlightCallback(lines[i])}
      />
    );
  }
  if (rows.length === 0) {
    rows.push(
      <TableRow key='info'><TableCell align="center" colSpan={4} variant="footer" >Nenhuma transferência encontrada</TableCell></TableRow>
    );
  }
  return rows;
}

const bg_highlight = Cookies.get('darkMode') === 'true' ? '#515151' : '#F1F1F1';

function TableRowComponent({ disabled, line, index, onClick, icon, showMU, highlight }) {

  const handleClick = (e) => {
    e.preventDefault();
    onClick(index);
  }

  return (
    <TableRow sx={{
      backgroundColor: index % 2 > 0 ? bg_highlight : 'inherit',
      backgroundBlendMode: 'darken',
      backgroundImage: highlight,
    }}>
      <TableCell sx={showMU ? {} : { display: 'none' }}>{line['employee']}</TableCell>
      <TableCell>{line['ItemCode']}</TableCell>
      <TableCell sx={showMU ? { display: { xs: 'none', lg: 'table-cell' } } : { display: 'none' }}>{line['Dscription']}</TableCell>
      <TableCell>{showMU ? parseFloat(line['Quantity']) - line['U_TextoLivre4'] : line['U_TextoLivre4']}</TableCell>
      <TableCell sx={showMU ? {} : { display: 'none' }}>{line['MeasureUnit']}</TableCell>
      <TableCell sx={!showMU ? { display: { xs: 'none', lg: 'table-cell' } } : { display: 'none' }}>
        {line['U_TextoLivre4'] >= line['Quantity'] ? 'Conferido' : 'Parcial'}
      </TableCell>
      <TableCell align="center">
        <IconButton disabled={disabled} onClick={handleClick} size="small">{icon}</IconButton>
      </TableCell>
    </TableRow>
  );
}

TransfersTable.defaultProps = {
  lines: [],
  showMU: false,
  onClick: () => { },
  enabledCallback: () => true,
  filterCallback: () => true,
  highlightCallback: () => false,
  isLoading: false,
};

export default TransfersTable;
