import dayjs from "dayjs";

export async function fetchStockTransfers(sessionID, item = null, fromWarehouse = null, toWarehouse = null, startDate = null, endDate = null, Comments = null) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/$crossjoin(StockTransfers,StockTransfers/StockTransferLines)");
  let data = [];
  let repeat = true;

  const expand = [
    'StockTransfers($select=DocEntry, DocDate, Comments)',
    'StockTransfers/StockTransferLines($select=DocEntry, LineNum, ItemCode, Quantity, WarehouseCode, FromWarehouseCode, BaseEntry, U_EASY_Colab)',
  ]
  apiUrl.searchParams.append('$expand', expand.join(','));

  const filters = [
    "StockTransfers/DocEntry eq StockTransfers/StockTransferLines/DocEntry",
  ];
  if (item) {
    filters.push(`contains(StockTransfers/StockTransferLines/ItemCode,'${item}')`);
  }
  if (fromWarehouse) {
    filters.push(`contains(StockTransfers/StockTransferLines/FromWarehouseCode,'${fromWarehouse}')`);
  }
  if (toWarehouse) {
    filters.push(`contains(StockTransfers/StockTransferLines/WarehouseCode,'${toWarehouse}')`);
  }
  if (startDate) {
    filters.push(`StockTransfers/DocDate ge '${startDate}'`);
  }
  if (endDate) {
    filters.push(`StockTransfers/DocDate le '${endDate}'`);
  }
  if (Comments) {
    filters.push(`contains(StockTransfers/Comments,'${Comments}')`);
  }
  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$orderby', 'StockTransfers/DocEntry desc, StockTransfers/StockTransferLines/LineNum desc');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            const line = item['StockTransfers/StockTransferLines'];
            line['id'] = String(line['DocEntry']).concat('-', line['LineNum']);
            line['date'] = dayjs(item['StockTransfers']['DocDate']).format('DD/MM/YYYY');
            line['Comments'] = item['StockTransfers']['Comments'];
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchStockTransfersLines(sessionID, item = null, fromWarehouse = null, toWarehouse = null, startDate = null, endDate = null, comments = null, employee = null) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('stockTransfersLinesXEmployees')/List");
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('itemCode', "'%'");
  if (item) {
    apiUrl.searchParams.set('itemCode', `'%${item}%'`);
  }
  apiUrl.searchParams.append('fromWhs', "'%'");
  if (fromWarehouse) {
    apiUrl.searchParams.set('fromWhs', `'%${String(fromWarehouse).toUpperCase()}%'`);
  }
  apiUrl.searchParams.append('toWhs', "'%'");
  if (toWarehouse) {
    apiUrl.searchParams.set('toWhs', `'%${String(toWarehouse).toUpperCase()}%'`);
  }
  if (startDate) {
    apiUrl.searchParams.append('startDate', `'${startDate}'`);
  }
  if (endDate) {
    apiUrl.searchParams.append('endDate', `'${endDate}'`);
  }
  apiUrl.searchParams.append('comments', "'%'");
  if (comments) {
    apiUrl.searchParams.set('comments', `'%${comments}%'`);
  }
  apiUrl.searchParams.append('employee', "'%'");
  if (employee) {
    apiUrl.searchParams.append('employee', `'${employee}'`);
  }

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=200'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            let line = item;
            line['id'] = String(line['DocNum']).concat('-', line['LineNum']);
            line['date'] = dayjs(line['DocDate']).format('DD/MM/YYYY');
            if (line['U_EASY_Colab']) {
              line['employee'] = String(line['U_EASY_Colab']).concat(' - ', line['firstName'], ' ', line['lastName']);
            }
            data.push(line);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return null;
      });
  }
  return data;
}

export async function fetchItems(sessionID, itemCode) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Items');
  const data = [];

  const select = [
    'ItemCode',
    'ItemName',
  ];
  apiUrl.searchParams.append('$select', select.join(','));

  const filters = [
    "contains(ItemCode,'".concat(itemCode, "')"),
  ];
  apiUrl.searchParams.append('$filter', filters.join(' and '));

  apiUrl.searchParams.append('$top', '5');
  apiUrl.searchParams.append('$orderby', 'ItemCode');

  await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.value.length > 0) {
        json.value.forEach((item) => {
          data.push({
            value: item['ItemCode'],
            label: String(item['ItemCode']).concat(' - ', item['ItemName']),
          });
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      return null;
    });

  return data;
}

export async function fetchWarehouses(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/Warehouses');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'WarehouseCode');

  apiUrl.searchParams.append('$filter', "Inactive eq 'tNO' and (BusinessPlaceID eq 11 or BusinessPlaceID eq 3)");

  apiUrl.searchParams.append('$orderby', 'WarehouseCode');

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            data.push({
              value: item['WarehouseCode'],
              label: item['WarehouseCode'],
            });
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}

export async function fetchBatches(sessionID, itemCode, warehouseCode) {
  const apiUrl = new URL("https://greylogix-sl.skyinone.net:50000/b1s/v1/SQLQueries('itemWarehouseBatch')/List");
  apiUrl.searchParams.append('itemCode', `'${itemCode}'`);
  apiUrl.searchParams.append('whsCode', `'${warehouseCode}'`);
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      Authorization: `Basic ${sessionID}`,
      Prefer: 'odata.maxpagesize=500'
    },
  });
  if (response.status !== 200) {
    throw await response.json();
  }
  return await response.json();
}

export async function createStockTransfer(sessionID, data) {
  const apiUrl = new URL(`https://greylogix-sl.skyinone.net:50000/b1s/v1/StockTransfers`);

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${sessionID}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    throw await response.json();
  }
  return await response.json();
}

export async function fetchEmployees(sessionID) {
  const apiUrl = new URL('https://greylogix-sl.skyinone.net:50000/b1s/v1/EmployeesInfo');
  let data = [];
  let repeat = true;

  apiUrl.searchParams.append('$select', 'EmployeeID,LastName,FirstName');
  apiUrl.searchParams.append('$filter', "Active eq 'tYES'");

  while (repeat) {
    repeat = false;
    apiUrl.searchParams.set('$skip', data.length);

    await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Basic ${sessionID}`,
        Prefer: 'odata.maxpagesize=500'
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.value.length > 0) {
          repeat = true;
          json.value.forEach((item) => {
            item['id'] = item['EmployeeID'];
            item['label'] = `${item['EmployeeID']} - ${item['FirstName']} ${item['LastName']}`
            data.push(item)
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        repeat = false;
        return null;
      });
  }
  return data;
}
