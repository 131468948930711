import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid, Step, StepLabel, Stepper,
} from '@mui/material';
import Cookies from 'js-cookie';

import { fetchProject } from '../fetchData'
import StepMap from './stepMap';
import StepImport from './stepImport';
import StepSave from './stepSave';
import StepFinish from './stepFinish';
import { patchProject } from 'layouts/ProjectMg/fetchData';

function ImportWizard({ open, onClose, onSubmit, id }) {

  const steps = [
    'Importe o arquivo',
    'Mapeie os campos',
    'Salve os items',
    'Finalizando a importação',
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [errorStep, setErrorStep] = useState(null);

  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [override, setOverride] = useState(false);

  const [items, setItems] = useState({});
  const [projects, setProjects] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeData = (value) => {
    const rows = {};
    for (let index = 0; index < value.length; index++) {
      const row = value[index];
      rows[row.id] = row;
    }
    setItems(rows);
    if (id) {
      setProjects({
        [id]: {
          id,
          lines: rows,
        }
      });
    }
    setData(value);
    if (errorStep === activeStep) {
      setErrorStep(null);
    }
  }

  const handleChangeFile = (value) => {
    setFile(value);
    if (value.data) {
      handleChangeData(value.data);
    }
  }

  const handleChangeOverride = (value) => {
    setOverride(value);
    setErrorStep(null);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setData([]);
    setErrorStep(null);
    setFile(null);
    setItems({});
    setOverride(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    handleClose(e);
  }

  const handleClose = (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onClose();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(data);
    handleReset();
    handleClose(e);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let error = false;
    const sessionID = Cookies.get('sessionID');

    for (const code in projects) {
      const row = projects[code];
      if (row.status === true) {
        continue;
      }
      row.status = false;
      setProjects(projects);

      const project = await fetchProject(sessionID, code, false);

      const lines = Object.values(row.lines).map((line) => ({
        Description: line.Description,
        ExpectedCosts: line.ExpectedCosts,
        StageType: line.StageType,
        StartDate: line.StartDate || project.StartDate,
        Task: line.Task,
        UniqueID: line.UniqueID,
        LineID: project.PM_StagesCollection.find((stage) => stage.UniqueID === line.UniqueID)?.LineID || undefined,
      }));

      let sucess = await patchProject(
        sessionID,
        code,
        {
          PM_StagesCollection: lines,
        },
      ).then(() => {
        row.status = true;
        setProjects(projects);
        return true;
      }).catch((error) => {
        console.error(error);
        if (typeof error === 'string') {
          row.status = error;
        } else {
          row.status = error.error.message.value;
        }
        setProjects(projects);
        setErrorStep(1);
      });
      if (!sucess) {
        error = true;
      }
    }
    setIsLoading(false);
    if (!error) {
      handleNext();
    }
  }

  return (
    <Dialog fullWidth maxWidth="lg" scroll="paper" open={open} onClose={handleClose}>
      <DialogTitle>{steps[activeStep]}</DialogTitle>
      <DialogContent dividers>
        <Steps activeStep={activeStep}>
          <StepImport onChange={handleChangeFile} value={file} />
          <StepMap onChange={handleChangeData} value={Object.values(items)} />
          <StepSave onChangeOverride={handleChangeOverride} override={override} value={Object.values(projects)} />
          <StepFinish value={Object.values(projects)} />
        </Steps>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} sx={{ width: '100%' }}>
              <Step>
                <StepLabel>Importar</StepLabel>
              </Step>
              <Step>
                <StepLabel error={errorStep === 1}>Mapear</StepLabel>
              </Step>
              <Step>
                <StepLabel>Salvar</StepLabel>
              </Step>
              <Step>
                <StepLabel>Finalizar</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              color="error" disabled={isLoading || activeStep + 1 === steps.length}
              onClick={handleCancel} variant="contained"
            >Cancelar</Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading || activeStep === 0 || activeStep + 1 === steps.length}
              onClick={handleBack}
            >Anterior</Button>
          </Grid>
          <Grid item>
            <Steps activeStep={activeStep}>
              <Button disabled={data.length === 0} onClick={handleNext}>Próximo</Button>
              <Button onClick={handleNext}>Próximo</Button>
              <>
                <Button color="secondary" disabled={isLoading} onClick={handleNext} variant="outlined">Pular</Button>
                <Button disabled={isLoading || errorStep !== null} onClick={handleSave} variant="contained">Salvar</Button>
              </>
              <>
                <Button color="secondary" onClick={handleReset} variant="outlined">Nova</Button>
                <Button onClick={handleSubmit} variant="contained">Finalizar</Button>
              </>
            </Steps>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ImportWizard.defaultProps = {
  open: false,
  onClose: () => { },
  onSubmit: () => { },
  id: null,
};

function Steps({ activeStep, children }) {
  return children[activeStep];
}

Steps.defaultProps = {
  activeStep: 0,
  children: [],
};

export default ImportWizard;
