import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export async function exportTemplate() {
  const templateUrl = process.env.PUBLIC_URL + '/TemplateEstruturaProduto.xlsx';

  const response = await fetch(templateUrl);
  const data = await response.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(data);

  const blob = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([blob]),
    dayjs().format('YYYY-MM-DD-HH-mm').concat('-', 'TemplateEstruturaProduto.xlsx')
  );
}

export async function importFromTemplate(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => importCallback(e, resolve, reject);
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

async function importCallback(e, resolve, reject) {
  const data = new Uint8Array(e.target.result);
  const workbook = XLSX.read(data, { type: 'array' });
  if (!checkWorkbook(workbook)) {
    return reject('Planilha inválida! Selecione uma planilha válida.');
  }
  try {
    const data = extractData(workbook);
    console.log(data);
    return resolve(data);
  } catch (error) {
    return reject(error);
  }
}

function checkWorkbook(workbook) {
  const w = workbook.Sheets[workbook.SheetNames[0]];
  const j = XLSX.utils.sheet_to_json(w, { header: 1 });
  const h = ['ItemCode', 'Tipo', 'Nº do item', 'Quantidade', 'Depósito', 'Setor', 'Projeto', 'Lista de Preços', 'Time', 'Centro de Custo'];
  if (j[0].toString() === h.toString()) {
    return true;
  }
  return false;
}

function extractData(workbook) {
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  let rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  const data = [];
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    if (row.length === 0 || row[0] === '') {
      continue;
    }
    const item = {
      id: data.length + 1,
      treeCode: row[0],
      itemType: row[1],
      itemCode: row[2],
      quantity: row[3],
      warehouse: row[4],
      distributionRule: row[5],
      project: row[6],
      priceList: row[7],
      distributionRule2: row[8],
      distributionRule3: row[9],
      status: null,
      line: 0
    };
    data.push(item);
  }
  return data;
}
