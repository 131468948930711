import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';

import { activities, fetchProjectID, createTimeSheet } from './fetchData';

export function exportApontamentosToXLSX(data) {
  const header = [
    'EmployeeID',
    'Nome',
    'Projeto',
    'Tarefa',
    'Descrição',
    'Criado',
    'Data',
    'Início',
    'Intervalo',
    'Fim',
    'Tempo Total',
    'Local',
    'Feriado',
    'Retrabalho',
    'Comentário',
  ];
  const rows = [];

  data.forEach((item) => {
    rows.push([
      item['UserID'],
      item['EmployeeName'],
      item['Project'],
      item['Activity'],
      item['Description'],
      item['Created'],
      item['Date'],
      item['StartTime'],
      item['Break'],
      item['EndTime'],
      item['EffectiveTime'],
      item['Local'],
      item['Holiday'],
      item['Retrabalho'],
      item['Observation'],
    ]);
  });

  const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'ApontamentosProjeto');
  XLSX.writeFile(wb, `${dayjs().format('YYYY-MM-DD-HH-mm')}-ApontamentosProjeto.xlsx`);
};

export async function exportTemplate(projects) {
  const templateUrl = process.env.PUBLIC_URL + '/TemplateApontamento.xlsx';

  const response = await fetch(templateUrl);
  const data = await response.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(data);
  // Create a copy of the workbook
  const modifiedWorkbook = new ExcelJS.Workbook();
  await modifiedWorkbook.xlsx.load(data);

  // Modify the 'Projetos' sheet in the copy
  const projetosSheet = modifiedWorkbook.getWorksheet('Projetos');

  for (let i = 2; i <= 400; i++) {
    projetosSheet.getCell(`A${i}`).value = projects[i - 2]?.ProjectName || '';
  }

  // Set data validation for the first column in 'Logs' sheet
  const logsSheet = modifiedWorkbook.getWorksheet('Logs');
  const dataValidationFormula = `Projetos!$A$2:$A$400`;  // Assuming data validation range
  const dataValidationFormula2 = `Tarefas!$A$2:$A$50`;  // Assuming data validation range
  const dataValidationFormula3 = `Local!$A$2:$A$5`;  // Assuming data validation range
  const dataValidationFormula4 = `Feriado!$A$2:$A$3`;  // Assuming data validation range

  logsSheet.getCell('C2').value = dayjs().format('YYYY-MM-DD');

  // Set new data validation for the first column in 'Logs' sheet
  logsSheet.getColumn(1).eachCell({ includeEmpty: true, row: 2 }, (cell) => {
    cell.dataValidation = {
      type: 'list',
      formulae: [dataValidationFormula],
      showErrorMessage: true,
      errorTitle: 'Projeto Inválido',
      error: 'Por favor, selecione um projeto válido da lista.',
    };
  });

  // Set new data validation for the first column in 'Logs' sheet
  logsSheet.getColumn(2).eachCell({ includeEmpty: true, row: 2 }, (cell) => {
    cell.dataValidation = {
      type: 'list',
      formulae: [dataValidationFormula2],
      showErrorMessage: true,
      errorTitle: 'Tarefa Inválida',
      error: 'Por favor, selecione uma tarefa válida da lista.',
    };
  });

  logsSheet.getColumn(8).eachCell({ includeEmpty: true, row: 2 }, (cell) => {
    cell.dataValidation = {
      type: 'list',
      formulae: [dataValidationFormula3],
      showErrorMessage: true,
      errorTitle: 'Local Inválido',
      error: 'Por favor, selecione um local válido da lista.',
    };
  });


  logsSheet.getColumn(9).eachCell({ includeEmpty: true, row: 2 }, (cell) => {
    cell.dataValidation = {
      type: 'list',
      formulae: [dataValidationFormula4],
      showErrorMessage: true,
      errorTitle: 'Valor Inválido',
      error: 'Por favor, selecione um valor válido da lista.',
    };
  });

  logsSheet.getCell(1, 1).dataValidation = null;
  logsSheet.getCell(1, 2).dataValidation = null;
  logsSheet.getCell(1, 8).dataValidation = null;
  logsSheet.getCell(1, 9).dataValidation = null;

  // Save the modified workbook
  const blob = await modifiedWorkbook.xlsx.writeBuffer();
  saveAs(
    new Blob([blob]),
    dayjs().format('YYYY-MM-DD-HH-mm').concat('-', 'TemplateApontamentoProjeto.xlsx')
  );
}

export async function importFromTemplate(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => importCallback(e, resolve);
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

async function importCallback(e, resolve) {
  const data = new Uint8Array(e.target.result);
  const workbook = XLSX.read(data, { type: 'array' });
  const sessionID = Cookies.get('sessionID');
  const employeeID = Cookies.get('logemployeeID');

  // Assume the sheet name is 'Sheet1'
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  // Extract data from the sheet
  const rows = XLSX.utils.sheet_to_json(sheet);

  // Iterate through rows and send post requests
  const results = await Promise.allSettled(rows.map(async (row, index) => {
    const { Projeto, Tarefa, Data, HoraInicio, HoraFim, Intervalo, Comentario, Local, Feriado } = row;
    if (Tarefa) {
      const TaskID = activities.find(activity => activity.label === Tarefa)?.id;
      if (!TaskID) {
        throw 'Tarefa não encontrada';
      }
      const ProjectID = await fetchProjectID(sessionID, Projeto);
      if (!ProjectID) {
        throw 'Projeto não encontrado';
      }
      const data = {
        DateFrom: Data,
        DateTo: Data,
        PM_TimeSheetLineDataCollection: [
          {
            Date: Data,
            EndTime: HoraFim,
            StartTime: HoraInicio,
            FinancialProject: Projeto,
            ActivityType: TaskID,
            ProjectID: ProjectID,
            StageID: 1,
            Break: Intervalo,
            U_EASY_Obs: Comentario,
            U_Local: Local,
            U_Feriado: Feriado ? "Sim" : null,
            U_Created: dayjs().format('YYYY-MM-DD'),
          }
        ],
        UserID: employeeID,
      };
      return createTimeSheet(sessionID, data);
    }
    throw 'Template inválido, por favor atualizar para versão mais recente.';
  }));
  resolve(results);
}
