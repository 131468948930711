import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export async function exportTemplate() {
  const templateUrl = process.env.PUBLIC_URL + '/TemplateFollowUp.xlsx';

  const response = await fetch(templateUrl);
  const data = await response.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(data);
  const modifiedWorkbook = new ExcelJS.Workbook();
  await modifiedWorkbook.xlsx.load(data);

  const blob = await modifiedWorkbook.xlsx.writeBuffer();
  saveAs(
    new Blob([blob]),
    dayjs().format('YYYY-MM-DD-HH-mm').concat('-', 'TemplateFollowUp.xlsx')
  );
}

export async function importFromTemplate(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => importCallback(e, resolve, reject);
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

async function importCallback(e, resolve, reject) {
  const data = new Uint8Array(e.target.result);
  const workbook = XLSX.read(data, { type: 'array', cellDates: true });
  if (!checkWorkbook(workbook)) {
    return reject('Planilha inválida! Selecione uma planilha válida.');
  }
  try {
    const data = extractData(workbook);
    console.log(data);
    return resolve(data);
  } catch (error) {
    return reject(error);
  }
}

function checkWorkbook(workbook) {
  const w = workbook.Sheets[workbook.SheetNames[0]];
  const j = XLSX.utils.sheet_to_json(w, { header: 1 });
  const h = [
    'Numerador',
    'Nº de ref. do cliente/fornecedor',
    'Nº do documento',
    'Código do cliente/fornecedor',
    'RAZÃO SOCIAL FORNECEDOR',
    'LineNum',
    'Código do projeto',
    'Data de lançamento',
    'Data de Faturamento Inicial',
    'Data do Faturamento',
    'Linha da data de entrega',
    'Nº do item',
    'DESCRIÇÃO ITEM',
    'QUANTIDADE PENDENTE ',
    'Texto livre',
    'Status',
    'TAG',
  ];
  if (j[0].toString() === h.toString()) {
    return true;
  }
  return false;
}

function extractData(workbook) {
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  let rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  const data = [];
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    if (row.length === 0 || !row[0]) {
      continue;
    }
    const item = {
      id: data.length + 1,
      docNum: row[2],
      lineNum: row[5],
      u_DataDeFaturamentoInicial: row[8] && dayjs(row[8], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      u_Grey_DtFat: row[9] && dayjs(row[9], 'DD/MM/YYYY').format('YYYY-MM-DD'),
      shipDate: row[10] && dayjs(row[10]).format('YYYY-MM-DD'),
      status: null,
    };
    data.push(item);
  }
  return data;
}
