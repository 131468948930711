import { TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dataGridText from 'assets/dataGridText';

const columns = [
  { field: 'id', headerName: 'Nº', width: 50, editable: false, },
  { field: 'ItemCode', headerName: 'Item', width: 150, editable: false, },
  { field: 'u_DataDeFaturamentoInicial', headerName: 'F. Inicial', width: 150, editable: false },
  { field: 'u_Grey_DtFat', headerName: 'Faturamento', width: 150, editable: false },
  { field: 'u_Dif', headerName: 'Antecipação/Atraso', width: 150, editable: false },
  { field: 'shipDate', headerName: 'Entrega', width: 150, editable: false },
  { field: 'WarehouseCode', headerName: 'Depósito', width: 100, editable: false, },
  { field: 'Quantity', headerName: 'Quantidade', width: 100, editable: false, },
  { field: 'ItemDescription', headerName: 'Descrição', width: 400, editable: false, },
  { field: 'hist_total', headerName: 'Qtd Hist', width: 100, editable: false, },
  {
    field: 'u_EASY_INfoAdic',
    headerName: 'Atualização - Entrega',
    renderCell: (params) => (
      <TextField
        fullWidth
        disabled
        multiline
        rows={2}
        value={params.row.u_EASY_INfoAdic}
        variant="standard"
      />
    ),
    width: 250,
  },

];

function ProductsTable({ data }) {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      style={{ height: '50vh' }}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSorting
      localeText={dataGridText}
    />
  );
}

export default ProductsTable;
