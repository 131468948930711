import React, { useEffect, useState } from 'react';
import {
  Alert, AppBar, Backdrop, Button, CircularProgress, Dialog,
  DialogContent, Divider, Grid, IconButton, InputLabel,
  TextField, Toolbar, Typography,
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { useStatus } from '../../hooks/useStatus';
import { activities, statuses, types, fetchProject, patchProjectManagement, updateProjectManagement, fetchPaymentMethods } from './fetchData';
import StagesTable from './subtable';
import StageSubForm from './subform';
import ImportWizard from './wizard';

const ProjectDetailModal = ({ open, onClose, id }) => {

  const [project, setProject] = useState(null);
  const [name, setName] = useState('');
  const [financialProject, setFinancialProject] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);

  const [payments, setPayments] = useState([]);

  const [stages, setStages] = useState([]);
  const [stagesIDs, setStagesIDs] = useState([]);

  const { toggleStatus } = useStatus();
  const [saving, setSaving] = useState(false);

  const [fetchingProject, setFetchingProject] = useState(false);
  const [fetchingPayments, setFetchingPayments] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showWizard, setShowWizard] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get('sessionID');
    setFetchingPayments(true);
    fetchPaymentMethods(sessionID).then((data) => {
      setPayments(data);
    }).finally(() => setFetchingPayments(false));
  }, []);

  useEffect(() => {
    setRefresh(false);
    if (id && !fetchingProject) {
      setFetchingProject(true);
      const sessionID = Cookies.get('sessionID');
      fetchProject(sessionID, id).then((data) => {
        if (data != null) {
          setProject(data);
          setName(data['ProjectName']);
          setFinancialProject(data['FinancialProject'] || '');
          setStartDate(dayjs(data['StartDate']));
          setEndDate(data['DueDate'] ? dayjs(data['DueDate']) : null);
          setType(types.find((type) => type.id === data['ProjectType']) || null);
          setStatus(statuses.find((status) => status.id === data['ProjectStatus']) || null);
          const items = [];
          const ids = [];
          data['PM_StagesCollection'].forEach((stage) => {
            const item = { ...stage };
            ids.push(item['StageID']);
            item['label'] = activities.find(activity => activity.id === item['Task'])?.label || item['StageType'];
            items.push(item);
          });
          setStages(items);
          setStagesIDs(ids);
        }
      }).finally(() => setFetchingProject(false));
    }
  }, [id, refresh]);

  const handleReset = () => {
    setProject(null);
    setName('');
    setFinancialProject('');
    setStartDate(dayjs());
    setEndDate(dayjs());
    setType(null);
    setStatus(null);
    setStages([]);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    handleReset();
    onClose();
  }

  const handleAddForm = () => {
    setEditIndex(null);
    setShowEdit(true);
  }

  const handleEditForm = (index) => {
    setEditIndex(index);
    setShowEdit(true);
  }

  const handleCloseForm = () => {
    setEditIndex(null);
    setShowEdit(false);
  }

  const handleOpenWizard = (e) => {
    e.preventDefault();
    setShowWizard(true);
  };

  const handleCloseWizard = () => {
    setShowWizard(false);
  };

  const handleSubmitWizard = () => {
    setShowWizard(false);
    setRefresh(true);
  }

  const handleFormSubmit = (stage) => {
    delete stage['TotalInvoice'];
    delete stage['TotalPurchase'];
    if (!editIndex) {
      stage.LineID = project['PM_StagesCollection'].length + 1;
      let stageID = stage.LineID;
      while (stagesIDs.includes(stageID)) {
        stageID += 1;
      }
      stage.StageID = stageID;
      stage.UniqueID = String(stage.StageID).concat(' - ', stage.UniqueID);
    }

    const data = {
      PM_StagesCollection: [stage],
    };
    const sessionID = Cookies.get('sessionID');

    setSaving('Salvando');
    setShowEdit(false);
    patchProjectManagement(sessionID, id, data)
      .then(() => {
        setEditIndex(null);
        setRefresh(true);
        toggleStatus('Sucesso', 'Estágio salvo com sucesso', 'success');
      })
      .catch((error) => {
        setShowEdit(true);
        toggleStatus('Erro ao salvar estágio', error, 'error');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const handleFormDelete = () => {
    const stages = project['PM_StagesCollection'].map((stage, index) => {
      if (index === editIndex) {
        return undefined;
      }
      delete stage['TotalInvoice'];
      delete stage['TotalPurchase'];
      return stage;
    })
    const data = {
      ...project,
      PM_StagesCollection: stages,
    }

    const sessionID = Cookies.get('sessionID');

    setSaving('Excluindo');
    setShowEdit(false);
    updateProjectManagement(sessionID, id, data)
      .then(() => {
        setEditIndex(null);
        setRefresh(true);
        toggleStatus('Sucesso', 'Estágio excluído com sucesso', 'success');
      })
      .catch((error) => {
        setShowEdit(true);
        toggleStatus('Erro ao excluir estágio', error, 'error');
      })
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#7c8286' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Gestão de Compras do Projeto {name}
          </Typography>
          <Button onClick={handleOpenWizard} startIcon={<UploadFile />} variant="contained">
            Importar
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Nome"
              value={name}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Projeto Financeiro"
              value={financialProject}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Data de Início"
              value={startDate.format('DD/MM/YYYY')}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Data de Término"
              value={endDate?.format('DD/MM/YYYY') || ''}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Tipo"
              value={type?.label || ''}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled
              fullWidth
              label="Status"
              value={status?.label || ''}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <StagesTable stages={stages} onAdd={handleAddForm} onEdit={handleEditForm} />
          </Grid>
        </Grid>
      </DialogContent>
      <Backdrop open={saving} sx={{ zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <Alert severity="info">{saving}...</Alert>
      </Backdrop>
      <Backdrop open={fetchingProject || fetchingPayments} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 3 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StageSubForm
        open={showEdit}
        line={stages[editIndex]}
        onClose={handleCloseForm}
        onSubmit={handleFormSubmit}
        onDelete={handleFormDelete}
        payments={payments}
      />
      <ImportWizard
        open={showWizard}
        onClose={handleCloseWizard}
        onSubmit={handleSubmitWizard}
        id={id}
      />
    </Dialog>
  );
}

ProjectDetailModal.defaultProps = {
  open: false,
  onClose: () => { },
  id: null,
};

export default ProjectDetailModal;

