import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { activities } from './fetchData';
import MonetaryInput from 'components/monetaryInput';

const StageSubForm = ({ line, onSubmit, onClose, onDelete, open, payments }) => {

  const [id, setID] = useState("");
  const [stageType, setStageType] = useState(6);
  const [startDate, setStartDate] = useState(dayjs());
  const [closeDate, setCloseDate] = useState(null);
  const [finishedDate, setFinishedDate] = useState(null);
  const [task, setTask] = useState(null);
  const [description, setDescription] = useState("");
  const [uniqueID, setUniqueID] = useState("");
  const [baseCost, setBaseCost] = useState("");
  const [expectedCosts, setExpectedCosts] = useState("");
  const [payment, setPayment] = useState("");

  const [shouldReset, setShouldReset] = useState(false);

  useEffect(() => {
    if (line) {
      setID(line['StageID']);
      setStageType(line['StageType']);
      setStartDate(dayjs(line['StartDate']));
      setCloseDate(line['CloseDate'] ? dayjs(line['CloseDate']) : null);
      setFinishedDate(line['FinishedDate'] ? dayjs(line['FinishedDate']) : null);
      setTask(line['Task'] ? activities.find(activity => activity.id === line['Task'])?.label : null);
      setDescription(line['Description']);
      setExpectedCosts(line['ExpectedCosts']);
      setUniqueID(line['UniqueID']);
      setBaseCost(line['U_BaseCost']);
      setPayment(line['U_CondPagamento'] ? payments.find(payment => payment.id === line['U_CondPagamento']) : null);
    } else if (shouldReset) {
      setShouldReset(false);
      handleReset();
    }
  }, [line]);

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const handleChangeCloseDate = (date) => {
    setCloseDate(date);
  }

  const handleChangeTask = (e, value) => {
    e.preventDefault();
    setTask(value);
    if (id) {
      setUniqueID(String(id).concat(' - ', value.label, ' - ', description));
    } else {
      setUniqueID(String(value.label).concat(' - ', description));
    }
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
    if (id) {
      setUniqueID(String(id).concat(' - ', task.label, ' - ', e.target.value));
    } else {
      setUniqueID(String(task.label).concat(' - ', e.target.value));
    }
  }

  const handleChangeExpectedCosts = (e) => {
    setExpectedCosts(e.target.value);
  }

  const handleChangeUniqueID = (e) => {
    setUniqueID(e.target.value);
  }

  const handleChangeBaseCost = (e) => {
    setBaseCost(e.target.value);
  }

  const handleChangePayment = (e, value) => {
    e.preventDefault();
    setPayment(value);
  }

  const handleReset = () => {
    setID("");
    setStageType(6);
    setStartDate(dayjs());
    setCloseDate(null);
    setFinishedDate(null);
    setTask("");
    setDescription("");
    setExpectedCosts(0);
    setUniqueID("");
    setBaseCost(0);
  }

  const handleClose = () => {
    handleReset();
    onClose();
  }

  const handleDelete = (e) => {
    e.preventDefault();
    setShouldReset(true);
    if (line) {
      delete line['Saldo'];
      delete line['Savings'];
    }
    onDelete();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShouldReset(true);
    if (line) {
      delete line['Saldo'];
      delete line['Savings'];
    }
    const data = {
      ...line,
      StageID: id,
      StageType: stageType,
      StartDate: startDate.format('YYYY-MM-DD'),
      CloseDate: closeDate ? closeDate.format('YYYY-MM-DD') : null,
      FinishedDate: finishedDate ? finishedDate.format('YYYY-MM-DD') : null,
      Task: task.id,
      Description: description,
      ExpectedCosts: expectedCosts,
      UniqueID: uniqueID,
      U_BaseCost: baseCost,
      U_CondPagamento: payment?.id,
    };
    delete data['label'];
    onSubmit(data);
  }

  return (
    <Dialog
      open={open} onClose={handleClose}
      maxWidth="md" fullWidth
      PaperProps={{
        component: 'form',
        onReset: handleReset,
        onSubmit: handleSubmit,
        id: 'project-stage-form'
      }}
    >
      <DialogTitle>{id ? 'Edição de' : 'Adicionar'} Estágio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              fullWidth
              onChange={handleChangeTask}
              options={activities}
              renderInput={(params) => <TextField {...params} label="Etapa" required />}
              value={task}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              label="Fornecedor"
              value={description}
              onChange={handleChangeDescription}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Previsto Compras"
              value={expectedCosts}
              onChange={handleChangeExpectedCosts}
              InputProps={{
                inputComponent: MonetaryInput
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Custo da Base Orçamentária"
              value={baseCost}
              onChange={handleChangeBaseCost}
              InputProps={{
                inputComponent: MonetaryInput
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              fullWidth
              onChange={handleChangePayment}
              options={payments}
              renderInput={(params) => <TextField {...params} label="Condição de Pagamento" />}
              value={payment}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data de Início"
                  value={startDate}
                  onChange={handleChangeStartDate}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data Fluxo"
                  value={closeDate}
                  onChange={handleChangeCloseDate}
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                <DatePicker
                  label="Data de Faturamento"
                  value={finishedDate}
                  onChange={setFinishedDate}
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" sx={!id && { display: 'none' }} onClick={handleDelete} variant="contained">Excluir</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="error" variant="text" onClick={handleClose}>Cancelar</Button>
        <Button color="warning" variant="contained" type="submit">Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};

StageSubForm.defaultProps = {
  line: null,
  onSubmit: () => { },
  open: false,
  onClose: () => { },
  payments: [],
};

export default StageSubForm;
